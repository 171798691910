import {React, useState, useEffect } from 'react';
import './App.css';
import { AppElement2 } from './AppElement2';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

function App() {

  const [apps, setApps] = useState([]);

  useEffect(() => {
    fetch("data.json") //TODO: Prendere da env
      .then(res => res.json(), )
      .then(
        (result) => {
          setApps(result);
        },
        (error) => {
          console.log('Error: ' + error);
          return <div>ERROR</div>
        }
      )
  }, [])

const elements = apps.map(app => {
  return  <Grid item xs={12} sm={12} md={6}> 
          <Container fixed>
          <AppElement2 data={app} key={app.name}></AppElement2>
          </Container>
          </Grid>
})
return (
  <Box sx={{ flexGrow: 1 }} className="wrapper">
    <Grid container spacing={1}>
      {elements}
      </Grid>
  </Box>
);
}

export default App;
