import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import './AppElement2.css'

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export function AppElement2(props) {

const technologies = props.data.technologies.map(tech => 
    <Chip label={tech} variant="outlined" />
)

  return (
    <Paper
      sx={{
        p: 2,
        margin: 'auto',
        maxWidth: 500,
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
      <Grid container spacing={2}>
        <Grid item>
          <ButtonBase sx={{ width: 128, height: 128 }}>
            <Img alt="complex" src={props.data.avatar} />
          </ButtonBase>
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={1}>
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1" component="div">
                <h2>{props.data.name}</h2>
              </Typography>
              <Typography variant="body2" gutterBottom>
                    {technologies}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs>
                <Typography variant="subtitle1" component="div" className="link-container">
                    <Link href={props.data.link} target="_blank" underline="hover">Vai</Link>
                </Typography>
            </Grid>
        </Grid>
        <Grid item>
              <Typography sx={{ cursor: 'pointer' }} variant="body2">
                {props.data.description}
              </Typography>
            </Grid>
</Grid>


    </Paper>
  );
}
